<template>
  <modal
    id="modalPlanilha"
    name="modalNamePlanilha"
    :height="'auto'"
    :width="'20%'"
    :draggable="true"
    :adaptive="true"
  >
    <div class="container position-relative h-100 p-4">
      <label
        for="input_planilha"
        class="btn-style-default-less-2 color-fff bg-0e5daf hoverStyleButton"
        >Fazer upload da planilha de alunos</label
      >
      <input
        type="file"
        name=""
        id="input_planilha"
        hidden
        @change="onChoseFile"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <p style="font-size: 11px; margin-top: 6px">
        <a href="/modelo-planilha.xlsx">Clique aqui</a> para fazer o download da
        planilha
      </p>
      <div></div>
    </div>
  </modal>
</template>

<script>
export default {
  data() {
    return {
      batch: null,
    };
  },
  mounted() {},
  methods: {
    onChoseFile(event) {
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        return this.sendPlanilha({
          file: input.files[0],
          unidade_id: this.$route.params.idunidade,
        });

        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        if (Math.round(input.files[0].size / 1024) >= 5000) {
          this.toastGlobal(
            "error",
            `Seu arquivo é maior que 5MB! Por favor ultilize um compressor ou selecione outro arquivo! \r `
          );

          return false;
        }
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          // this.planilha = input.files[0].name;
          this.sendPlanilha({
            file: e.target.result,
            unidade_id: this.$route.params.idunidade,
          });
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    },
    emitirAtualizarLista() {
      this.$emit("atualizar-lista");
    },
    sendPlanilha(obj) {
      var formData = new FormData();

      // add assoc key values, this will be posts values
      formData.append("file", obj.file);
      formData.append("unidade_id", obj.unidade_id);

      $.ajax({
        type: "POST",
        url: `${this.VUE_APP_CMS}api/import`,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        headers: {
          Authorization: this.$store.getters.getPerfilToken,
        },
        success: (response) => {
          this.toastGlobal("success", `Importação realizada com sucesso`);
          this.hideModal("modalNamePlanilha");
          this.emitirAtualizarLista();
          window.open(
            `${this.VUE_APP_CMS}api/import/${response.batch}`,
            "_blank"
          );
          // this.$store
          //   .dispatch("getUnidadesDB", {
          //     token: this.$store.getters.getPerfilToken,
          //   })
          //   .then((resolve) => {
          //     if (resolve.valid) {
          //       this.toastGlobal(
          //         "success",
          //         "Unidades carregadas com sucesso. "
          //       );
          //     } else {
          //       this.toastGlobal(
          //         "error",
          //         `Algo de errado ocorreu ao tentar carregar unidades ${response}`
          //       );
          //     }
          //   });
        },
        error: (response) => {
          if (response.responseJSON.data) {
            response.responseJSON.data.forEach((element, index) => {
              this.toastGlobal("error", `Erro ${index + 1}: ${element}`);
            });
            this.$store.dispatch("getUnidadesDB", {
              token: this.$store.getters.getPerfilToken,
            });
          } else {
            this.toastGlobal("error", `Erro ${response.message}`);
          }
        },
      });
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: 12px;
}
</style>
