<template>
  <div>
    <modal
      id="modalNovasUnidades"
      name="modalNameNovasUnidades"
      :height="'auto'"
      :width="'25%'"
      :adaptive="true"
      :draggable="true"
      @opened="getFromProps"
      @closed="editarImagem(false)"
    >
      <div
        class="
          d-flex
          align-items-center
          justify-content-center
          container
          primeira-div-modal
        "
      >
        <ModalExplicaCodigoGerado :type="'unidade'" />
        <div class="pt-3">
          <h2 class="title-dados pb-3" v-if="novaUnidade">Nova instituição</h2>
          <h2 class="title-dados pb-3" v-else>Editar instituição</h2>
          <div class="row text-left j-c-center">
            <div class="col-md-10">
              <ValidationObserver
                ref="form"
                tag="form"
                class="py-3 text-left"
                @submit.prevent="salvaUnidadeInstituicao()"
              >
                <label for="" class="title-dados mb-2"
                  >Logotipo da Instituição</label
                >

                <div class="mb-3" v-if="novaUnidade">
                  <input
                    type="file"
                    name="imgEscola"
                    id="logotipo"
                    hidden
                    @change="onChoseFile"
                    accept="image/x-png, image/gif, image/jpeg"
                  />
                  <cropper
                    v-if="obj.file"
                    :src="obj.file"
                    ref="cropper"
                    class="cropper"
                  />
                  <label for="logotipo" class="hoverStyleButton mt-3 mb-3">
                    <span class="btn-style-default color-fff bg-0e5caf"
                      >Selecionar Imagem</span
                    >
                  </label>
                </div>

                <div class="mb-3" v-else>
                  <div class="d-flex j-c-center">
                    <input
                      type="file"
                      name="imgEscola"
                      id="logotipo"
                      hidden
                      @change="onChoseFile"
                      accept="image/x-png, image/gif, image/jpeg"
                    />
                    <cropper
                      v-if="obj.file"
                      :src="obj.file"
                      ref="cropper"
                      class="cropper"
                    />
                  </div>
                  <label
                    for="logotipo"
                    class="hoverStyleButton mt-3 mb-3 d-flex j-c-center"
                  >
                    <span class="btn-style-default color-fff bg-0e5caf"
                      >Selecionar Imagem</span
                    >
                  </label>
                </div>

                <ValidationProvider
                  v-slot="{ errors, ariaMsg, classes }"
                  rules="required"
                  name="'nome da unidade'"
                  :bails="false"
                  class="input-padding"
                  tag="div"
                >
                  <div class="control" :class="classes">
                    <label for="" class="title-dados">Nome da unidade</label>
                    <input
                      placeholder="Nome da unidade"
                      class="form-control"
                      :class="classes"
                      type="text"
                      v-model="obj.name"
                    />
                    <div class="text-left">
                      <span v-bind="ariaMsg" class="span-status-validate">{{
                        errors[0]
                      }}</span>
                    </div>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors, ariaMsg, classes }"
                  rules="validadeCnpj|required"
                  name="'CNPJ da unidade'"
                  :bails="false"
                  class="input-padding"
                  tag="div"
                >
                  <div class="control" :class="classes">
                    <label for="" class="title-dados mt-3"
                      >CNPJ da unidade</label
                    >
                    <input
                      placeholder="CNPJ da unidade"
                      v-mask="'##.###.###/####-##'"
                      class="form-control"
                      :class="classes"
                      type="text"
                      v-model="obj.cnpj"
                    />
                    <div class="text-left">
                      <span v-bind="ariaMsg" class="span-status-validate">{{
                        errors[0]
                      }}</span>
                    </div>
                  </div>
                </ValidationProvider>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <form class="text-center">
                        <label for="corPrimaria">Cor Primária</label> <br />
                        <input
                          type="color"
                          name="corPrimaria"
                          id="corPrimaria"
                          v-model="obj.cor_primaria"
                        />
                      </form>
                    </div>
                    <div class="col-md-6">
                      <form action="" class="text-center">
                        <label for="corSecundaria">Cor Secundária</label>
                        <input
                          type="color"
                          name="corSecundaria"
                          id="corSecundaria"
                          v-model="obj.cor_secundaria"
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div
                    class="
                      mt-4
                      btns
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                  >
                    <button
                      class="btn-style-default color-fff bg-d42338"
                      @click="hideModal('modalNameNovasUnidades')"
                    >
                      cancelar
                    </button>
                    <button
                      class="btn-style-default color-fff bg-0e5caf ml-3"
                      type="submit"
                      @click="changeColor()"
                      :disabled="show"
                    >
                      {{ statusEdicao }}
                      <div v-if="show" class="spinner-border" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </button>
                    <!--<button class="btn-style-default color-fff bg-0e5caf ml-3" @click="chamaProximaModalLinkaTema()">próximo</button>-->
                  </div>
                </div>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import ModalExplicaCodigoGerado from "@/components/modais/ModalExplicaCodigoGerado";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      editarImagemUnidade: false,
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
      obj: {
        name: null,
        cnpj: null,
        file: require("../../assets/icones/iconeLogoTipo.png"),
        cor_primaria: "#000",
        cor_secundaria: "#000",
      },
      hasImage: false,
      show: false,
      statusEdicao: "Salvar",
    };
  },
  components: { ModalExplicaCodigoGerado },
  props: ["objEditarUnidade", "novaUnidade"],
  computed: {
    ...mapGetters(["getCodigoDaUltimaUnidade"]),
  },
  created() {
    this.getFromProps();
  },
  methods: {
    editarImagem(boolean) {
      this.editarImagemUnidade = boolean;
    },
    changeColor(val, id) {
      switch (val) {
        case 1:
          this.firstColor = document.getElementById(id).value;
          break;

        case 2:
          this.secondColor = document.getElementById(id).value;
          break;

        default:
          break;
      }
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      this.obj.file = canvas.toDataURL();
    },
    salvaUnidadeInstituicao() {
      //this.objEditarUnidade.id ? delete this.obj.file : null;
      this.$refs.form.validate().then((success) => {
        if (success) {
          if (this.obj.file) {
            this.crop();
          }

          $.ajax({
            type: `${this.objEditarUnidade.id ? "PUT" : "POST"}`,
            url: `${this.VUE_APP_CMS}api/unidades${
              this.objEditarUnidade.id ? `/${this.objEditarUnidade.id}` : ""
            }`,
            data: this.obj,
            dataType: "json",
            headers: {
              Authorization: this.$store.getters.getPerfilToken,
              // "Content-Type": "application/x-www-form-urlencodd"
            },
            success: (response) => {
              this.toastGlobal(
                "success",
                `A unidade '${this.obj.name}' foi devidamente cadastrada`
              );

              this.$store.dispatch("getUnidadesDB", {
                token: this.$store.getters.getPerfilToken,
              });
              this.hasImage = false;
              this.resetaFormulario();
              this.hideModal("modalNameNovasUnidades");
              this.$emit("atualizaDados");
            },
            error: (response) => {
              this.toastGlobal(
                "error",
                `Erro: ao tentar cadastrar. ${response.responseJSON.message}`
              );
            },
          });
        } else {
          this.toastGlobal("error", `Preencha o formulário devidamente`);
        }
      });
    },

    onChoseFile(event) {
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        if (Math.round(input.files[0].size / 1024) >= 5000) {
          this.toastGlobal(
            "error",
            `Seu arquivo é maior que 5MB! Por favor utilize um compressor ou selecione outro arquivo! \n`
          );

          return false;
        }
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.obj.file = e.target.result;
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
        this.hasImage = true;
      }
    },
    resetaFormulario() {
      this.obj.name = null;
      this.obj.cnpj = null;
      this.obj.file = require("../../assets/icones/iconeLogoTipo.png");
      this.obj.cor_primaria = "vazio";
      this.obj.cor_secundaria = "vazio";
    },
    getFromProps() {
      this.obj.cnpj = this.objEditarUnidade.id
        ? this.objEditarUnidade.cnpj
        : "";
      this.obj.name = this.objEditarUnidade.id
        ? this.objEditarUnidade.name
        : "";
      this.obj.file = this.objEditarUnidade.file
        ? this.objEditarUnidade.file
        : "";
      this.obj.cor_primaria = this.objEditarUnidade.cor_primaria
        ? this.objEditarUnidade.cor_primaria
        : "";
      this.obj.cor_secundaria = this.objEditarUnidade.cor_secundaria
        ? this.objEditarUnidade.cor_secundaria
        : "";
    },
  },
};
</script>

<style scoped>
.cropper {
  height: 200px;
  width: 300px;
  background: #ddd;
}
.color {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 1px solid #707070;
}

#logoTipo,
#logoTipo img {
  object-fit: cover;
}
.max {
  max-width: 150px;
}
label,
h2 {
  border: none !important;
  margin-bottom: 0 !important;
}

.border-no-file {
  border: 2.5px solid #d42338;
  border-radius: 5px;
}

.border-file {
  border: 5px solid #42cc7e;
}
#corPrimaria {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: none;
  padding: 0;
  background-color: #fff;
  cursor: pointer;
}
#corPrimaria:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
}
#corSecundaria {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: none;
  padding: 0;
  background-color: #fff;
  cursor: pointer;
}
#corSecundaria:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
}

.input-file {
  padding: 5px;
}
</style>
