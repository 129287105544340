import { render, staticRenderFns } from "./AdministradorTelaInstituicoes.vue?vue&type=template&id=e8ade830&scoped=true&"
import script from "./AdministradorTelaInstituicoes.vue?vue&type=script&lang=js&"
export * from "./AdministradorTelaInstituicoes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8ade830",
  null
  
)

export default component.exports