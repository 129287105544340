import { render, staticRenderFns } from "./ModalPlanilha.vue?vue&type=template&id=71400a6a&scoped=true&"
import script from "./ModalPlanilha.vue?vue&type=script&lang=js&"
export * from "./ModalPlanilha.vue?vue&type=script&lang=js&"
import style0 from "./ModalPlanilha.vue?vue&type=style&index=0&id=71400a6a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71400a6a",
  null
  
)

export default component.exports