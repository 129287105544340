<template>
  <transition-group name="fade">
    <div class="here bg-fff py-5" v-if="show" key="administradorinstituicoes">
      <modalNovasUnidades
        :objEditarUnidade="dadosEdicao"
        @atualizaDados="atualizaDados"
        :novaUnidade="tipoModal"
      />
      <ModalConfirmacao
        :vontade="vontade"
        :elementoVontade="elementoVontade"
        :funcaoVontade="deletarUnidade"
        @deletarUnidade="deletarUnidade"
      />
      <!-- <ModalPlanilha /> -->
      <div class="container">
        <div class="row">
          <div class="lupa col-md-12 mx-auto text-center d-flex my-4 px-0">
            <div class="row">
              <div class="col-md-12">
                <div class="position-relative">
                  <img
                    class="position-absolute"
                    src="@/assets/icones/lupa.png"
                    alt="lupa.png"
                  />
                  <input
                    v-model="inputPesquisar"
                    type="text"
                    placeholder="Digite o nome ou o código para pesquisar "
                    class="shadow-sm border-default shadow-sm"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-left pl-0 mb-3">
            <h2 class="title-dados pb-2">Unidades/Instituições Cadastradas</h2>
          </div>
        </div>
      </div>
      <div class="container bg-fff in-here shadow-sm">
        <div class="row pb-4">
          <div
            class="
              col-md-11
              mx-auto
              h2
              row
              text-center
              border-bottom
              mt-5
              pb-2
              px-0
              pr-2
            "
          >
            <div class="col-md-1 p-0">
              <h2>Código</h2>
            </div>
            <div class="col-md-2 p-0">
              <h2>CNPJ</h2>
            </div>
            <div class="col-md-3 p-0">
              <h2>Unidade</h2>
            </div>
            <div class="col-md-2 pl-0">
              <h2>Cadastrada</h2>
            </div>
          </div>
          <div
            class="col-md-11 mx-auto h2 row text-center px-0"
            v-for="(item, index) in unidades"
            :key="`${item}_${index}`"
          >
            <div class="row w-100 p-3 border-bottom" v-if="validacao(item.id)">
              <div class="col-md-1 d-flex j-c-center p-0">
                <h3>{{ item.id | verificaLength }}</h3>
              </div>
              <div class="col-md-2 d-flex j-c-center p-0">
                <h3>{{ item.cnpj }}</h3>
              </div>
              <div class="col-md-3 d-flex j-c-center p-0">
                <h3>{{ item.name }}</h3>
              </div>
              <div class="col-md-2 d-flex j-c-center p-0">
                <h3>{{ item.created_at | trataDataHora }}</h3>
              </div>
              <div class="col-md-4 d-flex justify-content-end pr-0">
                <router-link
                  :to="`/administrador/unidades-e-instituicoes/${item.id}/${item.name}`"
                  class="color-fff"
                >
                  <button
                    class="
                      btn-style-default-less-2
                      color-fff
                      bg-222541
                      mr-3
                      p-3
                      tamanho
                      d-flex
                      j-c-center
                    "
                  >
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </button>
                </router-link>

                <button
                  v-if="$store.getters.getPerfil != 4"
                  @click="editarUnidade(item)"
                  class="
                    btn-style-default-less-2
                    color-fff
                    bg-fedd0a
                    mr-3
                    p-3
                    tamanho
                  "
                >
                  <i class="far fa-edit cor"></i>
                </button>
                <button
                  v-if="$store.getters.getPerfil != 4"
                  @click="verificaSeIraDeletar(item.name, item.id)"
                  class="
                    btn-style-default-less-2
                    color-fff
                    bg-d42338
                    mr-3
                    p-3
                    tamanho
                  "
                >
                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
          <div
            class="col-md-11 mx-auto d-flex justify-content-start mt-5 px-0"
            v-if="$store.getters.getPerfil != 4"
          >
            <button
              class="btn-style-default-less-2 color-fff bg-0e5daf mr-3"
              @click="novaUnidade()"
            >
              Nova Instituição
              <i class="icon-plus ml-1"
                ><img src="@/assets/icones/iconPlus.png" alt="iconPlus.png"
              /></i>
            </button>
            <!-- <button
              @click="showModal('modalNamePlanilha')"
              class="btn-style-default-less-2 color-fff bg-0e5daf"
            >
              Cadastrar por Planilha
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
import modalNovasUnidades from "@/components/modais/ModalNovasUnidades";
import ModalConfirmacao from "@/components/modais/ModalConfirmacao";
import ModalPlanilha from "@/components/modais/ModalPlanilha";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      show: false,
      page: 1,
      statusSalva: {
        target: "Nova unidade",
        status: "Adicionado com sucesso",
      },
      inputPesquisar: null,
      unidades: [],
      IdUnidade: null,
      vontade: null,
      elementoVontade: "deletada",
      dadosEdicao: {},
      dataInicio: null,
      dataFinal: null,
      tipoModal: true,
    };
  },
  mounted() {
    this.show = true;
    this.atualizaDados();
  },
  watch: {
    inputPesquisar: function () {
      this.unidades = this.filtrosDePesquisaDados(
        this.inputPesquisar,
        "unidades"
      );
    },
    getUnidades: function () {
      this.unidades = this.filtrosDePesquisaDados("", "unidades");
    },
  },
  computed: {
    ...mapGetters(["getUnidades"]),
  },
  components: {
    modalNovasUnidades,
    ModalConfirmacao,
    ModalPlanilha,
  },
  methods: {
    emitParaMudarCP(val) {
      this.$emit("mudaComponente", val);
    },
    atualizaDados() {
      this.getTheUnidades();
      this.unidades = this.filtrosDePesquisaDados("", "unidades");
    },
    getTheUnidades() {
      this.$store
        .dispatch("getUnidadesDB", {
          token: this.$store.getters.getPerfilToken,
        })
        .then((resolve) => {
          if (resolve.valid) {
            this.toastGlobal("success", "Unidades carregadas com sucesso. ");
          } else {
            this.toastGlobal(
              "error",
              `Algo de errado ocorreu ao tentar carregar unidades ${response}`
            );
          }
        });
    },
    verificaSeIraDeletar(name, id) {
      this.showModal("modalNameConfirmacao");
      this.vontade = `que a unidade '${this.funcaoFiltroLimitaChars(
        name
      )}' seja`;
      this.IdUnidade = id;
    },
    deletarUnidade() {
      this.hideModal("modalNameConfirmacao");
      this.$store.dispatch("deletePutUnidadesDB", {
        type: "DELETE",
        id: this.IdUnidade,
        token: this.$store.getters.getPerfilToken,
      });
    },
    editarUnidade(obj) {
      this.showModal("modalNameNovasUnidades");
      this.dadosEdicao = obj;
      this.tipoModal = false;
    },
    novaUnidade() {
      this.dadosEdicao = {};
      this.showModal("modalNameNovasUnidades");
      this.tipoModal = true;
    },
    validacao(id) {
      if (this.$store.getters.getUserDatas.profile_id == 4) {
        if (this.$store.getters.getUserDatas.unidade_id == id) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.cropper {
  height: 200px;
  width: 200px;
  background: #ddd;
}
h2 {
  font-size: 14px;
}

h3,
button {
  font-size: 12px;
  font-weight: 400;
}

.cor {
  color: #000;
}

input {
  height: 40px;
  border: 1px solid #e4e5e8;
}

.border-bottom {
  border-bottom: 1px solid #e4e5e8;
}

input[type="date"] {
  width: 140px;
}

.date label {
  margin: 0;
  font-size: 10px;
}
</style>
